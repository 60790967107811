import React, { ReactNode } from "react";
import { Grid, GridContainer, GridContent, FullGridContainer } from "@opr-finance/component-grid";
import { BoxContainer } from "@opr-finance/component-box-container";
import { BaseLayout, GlobalStyles, GlobalStylesProps } from "./Layout.styled";
import { Footer } from "@opr-finance/component-footer";
import { SystemStyleObject } from "@styled-system/css";
import { Font } from "@opr-finance/component-fonts";
import styled from "styled-components";

export type LayoutProps = {
    logo: ReactNode;
    children: ReactNode;
    styleConfig: {
        footerTitle: SystemStyleObject;
        footerLink: SystemStyleObject;
        footerContact: SystemStyleObject;
        footerText: SystemStyleObject;
    };
    icons: {
        time: ReactNode;
        phone: ReactNode;
        email: ReactNode;
        faq: ReactNode;
    };
    translation: {
        footer: {
            customerServiceTitle: string;
            customerServiceLine1: string;
            customerServiceLine2: string;
            customerServiceLine3: string;
            customerServiceLine4: string;
            customerServiceLink: string;
            contactTitle: string;
            contactLine1: string;
            contactLine2: string;
            contactLine3: string;
            contactLine4: string;
        };
    };
} & GlobalStylesProps;

const FooterItem = styled.div`
    display: flex;
    flex-direction: column;
`;

const FooterItemCustomerService = styled.div`
    display: flex;
    flex-direction: column;
    margin: -10px 0 0 0;
`;

export function Layout(props: LayoutProps) {
    return (
        <BaseLayout>
            <GlobalStyles bodyBackgroundImage={props.bodyBackgroundImage} />
            <GridContainer
                borderBottom="1px solid #A9D4E5"
                padding="0"
                height="125px"
                justifyContent="center"
            >
                <Grid width="100%" padding="45px 0 30px 0">
                    {props.logo}
                </Grid>
            </GridContainer>
            <GridContainer padding="20px 0 0 0">{props.children}</GridContainer>
            <FullGridContainer>
                <Footer
                    columns={[
                        {
                            content: () => {
                                return (
                                    <FooterItem>
                                        <Font styleConfig={{ root: props.styleConfig.footerTitle }}>
                                            {props.translation.footer.customerServiceTitle}
                                        </Font>
                                        <Font styleConfig={{ root: props.styleConfig.footerText }}>
                                            {props.icons.time}
                                            {props.translation.footer.customerServiceLine1}
                                        </Font>
                                        <Font styleConfig={{ root: props.styleConfig.footerText }}>
                                            {props.icons.phone}
                                            {props.translation.footer.customerServiceLine2}
                                        </Font>
                                        <Font styleConfig={{ root: props.styleConfig.footerText }}>
                                            {props.icons.email}
                                            {props.translation.footer.customerServiceLine3}
                                        </Font>
                                        <Font
                                            styleConfig={{ root: props.styleConfig.footerLink }}
                                            onClick={() => {
                                                window.location.href =
                                                    props.translation.footer.customerServiceLink;
                                            }}
                                        >
                                            {props.icons.faq}
                                            {props.translation.footer.customerServiceLine4}
                                        </Font>
                                    </FooterItem>
                                );
                            },
                            order: 1,
                        },
                        {
                            content: () => {
                                return (
                                    <FooterItem>
                                        <Font styleConfig={{ root: props.styleConfig.footerTitle }}>
                                            {props.translation.footer.contactTitle}
                                        </Font>
                                        <FooterItemCustomerService>
                                            <Font
                                                styleConfig={{
                                                    root: props.styleConfig.footerContact,
                                                }}
                                            >
                                                {props.translation.footer.contactLine1}
                                            </Font>
                                            <Font
                                                styleConfig={{
                                                    root: props.styleConfig.footerContact,
                                                }}
                                            >
                                                {props.translation.footer.contactLine2}
                                            </Font>
                                            <Font
                                                styleConfig={{
                                                    root: props.styleConfig.footerContact,
                                                }}
                                            >
                                                {props.translation.footer.contactLine3}
                                            </Font>
                                            <Font
                                                styleConfig={{
                                                    root: props.styleConfig.footerContact,
                                                }}
                                            >
                                                {props.translation.footer.contactLine4}
                                            </Font>
                                        </FooterItemCustomerService>
                                    </FooterItem>
                                );
                            },
                            order: 2,
                        },
                    ]}
                />
            </FullGridContainer>
        </BaseLayout>
    );
}
