import css, { SystemStyleObject } from "@styled-system/css";
import React, { ReactNode, useState, useEffect } from "react";
import styled from "styled-components";
import { Icon } from "@opr-finance/component-icon";

type StyleConfig = {
    styleConfig: {
        root?: SystemStyleObject;
    };
};

const TooltipHeaderContainer = styled.div<StyleConfig>`
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    @media (max-width: 1023px) {
        position: relative;
        margin: 0;
    }
    ${(props) => {
        return css(props.styleConfig.root);
    }}
`;

const TooltipContainer = styled.div<StyleConfig>`
    cursor: pointer;
    position: absolute;
    margin: 30px -9px 0 -180px;
    z-index: 1000000000;
    width: 288px;
    background: #d8d8d8;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 16px;
    background-image: linear-gradient(-180deg, #ffffff 16%, #ebebeb 93%);
    &::before {
        content: "";

        position: absolute;
        top: -23px;
        left: 240px;
        transform: translateX(80%);

        border: 12px solid green;

        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        border-bottom-color: #adadad;

        width: 0;
        height: 0;

        margin: 0 auto;
    }
    &::after {
        content: "";

        position: absolute;
        top: -20px;
        left: 240px;
        transform: translateX(80%);

        border: 12px solid green;

        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        border-bottom-color: white;

        width: 0;
        height: 0;

        margin: 0 auto;
    }
    @media (max-width: 1023px) {
        right: -7px;
        &::before {
            left: 233px;
        }
        &::after {
            left: 233px;
        }
    }
    ${(props) => {
        return css(props.styleConfig.root);
    }}
`;

const TooltipContent = styled.div<StyleConfig>`
    font-weight: 300;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    color: #444444;
    letter-spacing: 0;
    line-height: 24px;
    ${(props) => {
        return css(props.styleConfig.root);
    }}
`;

type TooltipContentContainerProps = {
    children: ReactNode;
    isOpen: boolean;
    styleConfig?: {
        tooltipContainer?: SystemStyleObject;
        tooltipContent?: SystemStyleObject;
    };
};

function TooltipContentContainer(props: TooltipContentContainerProps) {
    if (!props.isOpen) {
        return null;
    }
    return (
        <TooltipContainer styleConfig={{ root: props.styleConfig?.tooltipContainer }}>
            <TooltipContent styleConfig={{ root: props.styleConfig?.tooltipContent }}>
                {props.children}
            </TooltipContent>
        </TooltipContainer>
    );
}

export type TooltipProps = {
    children: ReactNode;
    icon?: ReactNode;
    delayClose?: number;
    styleConfig?: {
        tooltipContainer?: SystemStyleObject;
        tooltipContent?: SystemStyleObject;
        root?: SystemStyleObject;
    };
};

export function Tooltip(props: TooltipProps) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    if (props.delayClose) {
        useEffect(() => {
            const timer = setTimeout(() => {
                setIsTooltipOpen(false);
            }, props.delayClose);
            return () => clearTimeout(timer);
        }, [isTooltipOpen]);
    }

    return (
        <TooltipHeaderContainer
            styleConfig={{
                root: props.styleConfig && props.styleConfig.root ? props.styleConfig.root : {},
            }}
            onClick={() => {
                setIsTooltipOpen(isTooltipOpen === true ? false : true);
            }}>
            {props.icon ? props.icon : <Icon icon={["far", "question-circle"]} />}
            <TooltipContentContainer
                isOpen={isTooltipOpen}
                styleConfig={
                    props.styleConfig
                        ? {
                              tooltipContainer: props.styleConfig.tooltipContainer,
                              tooltipContent: props.styleConfig.tooltipContent,
                          }
                        : {
                              tooltipContainer: {},
                              tooltipContent: {},
                          }
                }>
                {props.children}
            </TooltipContentContainer>
        </TooltipHeaderContainer>
    );
}
