import { SystemStyleObject } from "@styled-system/css";
import { TextAlignProperty } from "csstype";

export function tpTitleText(
    textAlign: TextAlignProperty = "center",
    color: string = "black"
): SystemStyleObject {
    return {
        width: "100%",
        fontFamily: "arial",
        fontWeight: "bold",
        margin: "0 0 10px 0",
        fontSize: "20px",
        textAlign,
        color,
    };
}

export function tpInfoText(): SystemStyleObject {
    return {
        width: "300px",
        fontFamily: "Roboto, sans-serif",
        fontSize: "17px",
        color: "white",
        margin: "0 0 0 10px",
        textAlign: "left",
    };
}
