import { SystemStyleObject } from "@styled-system/css";
import { SxProps, Theme } from "@mui/material/styles";

export function footerTitle(): SystemStyleObject {
    return {
        fontFamily: "basic",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#2bace2",
        marginBottom: "36px",
    };
}

export function footerLink(): SystemStyleObject {
    return {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontFamily: "basic",
        color: "#0c445c",
        textDecoration: "none",
        padding: "0 0 24px 0",
        height: "24px",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    };
}

export function footerText(): SystemStyleObject {
    return {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontFamily: "basic",
        color: "#0c445c",
        textDecoration: "none",
        padding: "0 0 40px 0",
        height: "24px",
    };
}

export function footerContact(): SystemStyleObject {
    return {
        fontSize: "14px",
        fontFamily: "basic",
        color: "#0c445c",
        textDecoration: "none",
        padding: "-16px 0 0 0",
    };
}

export function body(): SystemStyleObject {
    return {
        fontFamily: "basic",
        fontSize: "16px",
        fontWeight: "400",
        color: "#0c445c",
        padding: "8px 0 0 0",
    };
}

export function bodyTitle(): SystemStyleObject {
    return {
        fontFamily: "basic",
        fontSize: "20px",
        fontWeight: "bold",
        color: "#0c445c",
        padding: "32px 0 8px 0",
    };
}

export function pageTitle(): SystemStyleObject {
    return {
        fontFamily: "basic",
        fontSize: "32px",
        fontWeight: "bold",
        color: "#0c445c",
        padding: "8px 0 0 0",
    };
}

export function textField(): SystemStyleObject {
    return {
        border: "solid 1px #a9d3e5",
        borderRadius: "5px",
        boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
        backgroundColor: "#fff",
        color: "#0c445c",
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "1.5",
        width: "100%",
        height: "30px",
        padding: "8px 8px 8px 8px",
        margin: "8px 0 0 0",
    };
}

export function datePicker(): SxProps<Theme> {
    return {
        border: "solid 1px #a9d3e5",
        borderRadius: "5px",
        boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
        backgroundColor: "#fff",
        color: "#0c445c",
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "1.5",
        width: "100%",
        height: "30px",
        padding: 0,
        margin: "8px 0 16px 0",
        "& .MuiOutlinedInput-input": {
            border: 0,
            color: "#0c445c",
            fontSize: "16px",
            fontWeight: "normal",
            lineHeight: "1.5",
            marginLeft: "3px",
            padding: "3px 5px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
        },
    };
}

export function select(): SystemStyleObject {
    return {
        border: "solid 1px #a9d3e5",
        borderRadius: "5px",
        boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
        backgroundColor: "#fff",
        color: "#0c445c",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "1.5",
        width: "100%",
        height: "30px",
        padding: "0 8px 0 8px",
        margin: "8px 0 0 0",
        cursor: "pointer",
    };
}

export function checkbox(): SystemStyleObject {
    return {
        border: "none",
        borderRadius: "5px",
        boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
        backgroundColor: "#fff",
        color: "#0c445c",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "1.5",
        width: "auto",
        height: "30px",
        padding: "0 8px 0 8px",
        margin: "0 0 0 0",
    };
}

export function checkboxText(): SystemStyleObject {
    return {
        fontFamily: "basic",
        fontSize: "16px",
        fontWeight: "400",
        color: "#0c445c",
        padding: "0 0 0 8px",
    };
}

export function button(): SystemStyleObject {
    return {
        border: "0px",
        borderRadius: "5px",
        backgroundColor: "transparent",
        backgroundImage: "linear-gradient(to top, #59b268, #afe681)",
        height: "34px",
        "&:hover": {
            backgroundImage:
                "linear-gradient(to top, rgba(89, 178, 104, 0.7), rgba(175, 230, 129, 0.7));",
        },
    };
}

export function buttonText(): SystemStyleObject {
    return {
        fontFamily: "basic",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#fff",
        textTransform: "uppercase",
    };
}

export function formError(): SystemStyleObject {
    return {
        fontFamily: "basic",
        fontWeight: "bold",
        fontSize: "14px",
        color: "darkred",
        padding: "8px 0 0 0",
    };
}

export function completedPageBody(): SystemStyleObject {
    return {
        fontFamily: "basic",
        fontSize: "16px",
        fontWeight: "400",
        color: "#0c445c",
        padding: "8px 0 4px 0",
    };
}

export function link(): SystemStyleObject {
    return {
        color: "#428bca",
        textDecoration: "none",
        fontSize: "16px",
        "&:hover": {
            color: "#2a6496",
            textDecoration: "underline",
        },
    };
}
