export { theme } from "./theme";
export {
    footerContact,
    footerLink,
    footerText,
    footerTitle,
    body,
    bodyTitle,
    button,
    checkbox,
    checkboxText,
    pageTitle,
    select,
    textField,
    buttonText,
    completedPageBody,
    formError,
    link,
    datePicker,
} from "./styles";

export * as tpStyles from "./styles/trustPilot";
